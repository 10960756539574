<template>
	<div>
		<page-header
			:title="`Payout summary for ${start} - ${end}`"
			pre-title="VEHICLE PARTNERS OVERVIEW"
			:loading="false">
		</page-header>
		<div class="mx-4 p-1">
			<p>
				<router-link to="/partners" class="pay-nav">Vehicle Partners<img src="@/assets/img/caret-right.svg" alt=""></router-link>
				<router-link :to="{ name:'PartnerDetails', params:{accountSid: partner?.account_sid, partnerId: partner?.id }}" class="pay-nav" >{{partner?.owner.fname}} {{ partner?.owner.lname }}<img src="@/assets/img/caret-right.svg" alt=""></router-link>
				<router-link :to="{ name:'PastPartnerPayout', params:{ partnerId: partner?.id }}" class="pay-nav" >Past payouts<img src="@/assets/img/caret-right.svg" alt="" ></router-link>
				Payout summary
			</p>
		</div>
		<div class="card mx-4">
			<div class="card-header justify-content-between">
				<div>
					<fieldset class="d-flex flex-row" id="group2">
						<div class="d-flex align-items-center">
							<input
								v-model="statusFilter"
								class="form-control list-search"
								type="radio"
								name="group2"
								value="all"
								/>
							<label class="m-2">All</label>
						</div>
						<div class="d-flex align-items-center">
							<input
								v-model="statusFilter"
								class="form-control list-search"
								type="radio"
								name="group2"
								value="credit"
								/>
							<label class="m-2">Credits</label>
						</div>
						<div class="d-flex align-items-center">
							<input
								v-model="statusFilter"
								class="form-control list-search"
								type="radio"
								name="group2"
								value="debit"
								/>
							<label class="m-2">Debits</label>
						</div>
					</fieldset>
				</div>
			</div>
			<b-table striped hover selectable responsive show-empty :items="payoutSummary" :fields="fields"
				:per-page="pageSize" :current-page="currentPage" :busy="fetchSummary">
				<template #table-busy>
					<div class="d-flex flex-column justify-content-center align-items-center">
						<div class="spinner-border" role="status"></div>
						<p class="text-center mt-2"><strong>Loading...</strong></p>
					</div>
				</template>
				<template #empty>
					<p class="text-center text-secondary">No records available</p>
				</template>
				<!-- <template #cell(amount)="data">
                    <span :class="[data.item.amount.startsWith('-') ? 'text-danger' : '']">
                {{data.item.amount}}

                </span>
                </template> -->
				<template #cell(routeCode)="data">
					{{ data.item.routeCode ?  data.item.routeCode : 'N/A'  }}
				</template>
			</b-table>
			<div class="card-footer">
				<div class="row align-items-center">
					<div class="col">
						<span class="text-dark font-weight-bold">{{ tablePageText }}</span>
					</div>
					<div class="col-auto">
						<b-pagination
							v-model="currentPage"
							:total-rows="totalRecords"
							:per-page="pageSize"></b-pagination>
					</div>
				</div>
			</div>

		</div>

	</div>
</template>

<script>
import PageHeader from '@/components/layout/PageHeader'
import TableView from '@/mixins/tableview'

export default {
  name: 'PayoutSummary',
  components: { PageHeader },
  mixins: [TableView],
  data () {
    return {
      statusFilter: 'credit',
      currentPage: 1,
      pageSize: 10,
      totalRecords: 0,
      earningId: this.$route.params.earningId,
      id: parseInt(this.$route.params.id),
      partner: null,
      start: this.$route.params.start,
      end: this.$route.params.end,
      fetchSummary: true,
      fields: [
        {
          key: 'date',
          label: 'Date'
        },
        {
          key: 'time',
          label: 'Time'
        },
        {
          key: 'routeCode',
          label: 'RouteCode'
        },
        {
          key: 'amount',
          label: 'Amount(₦)'
        },
        {
          key: 'type',
          label: 'Type'
        },
        {
          key: 'description',
          label: 'Description'
        }
      ],
      payoutSummary: []
    }
  },
  watch: {
    statusFilter: function (val, oldVal) {
      this.getPayoutSummary()
    }
  },
  methods: {
    async getPartner () {
      try {
        const res = await this.axios.get(`v1/partners/${this.id}`)
        if (res.status == 200) {
          this.partner = res.data
        }
      } catch (err) {
        
      } finally {
        this.getPayoutSummary()
      }
    },
    async getPayoutSummary () {
      this.fetchSummary = true
      try {
        const res = await this.axios.get(`cost-revenue/v1/earnings/${this.earningId}/transactions?type=${this.statusFilter}`)
        if (res.status == 200) {
          const r = res.data.result
          r.forEach((item) => {
            const e = new Date(item.createdAt)
            item.date = e.toDateString()
            item.time = e.toLocaleTimeString()
          })
          this.payoutSummary = r
          this.totalRecords = res.data.metadata.total
        }
      } catch (err) {
        
      } finally {
        this.fetchSummary = false
      }
    }
  },
  created () {
    this.getPartner()
  }
}

</script>

<style lang="scss" scoped>

.pay-nav {
    color: #B9BCC8;
}

</style>
